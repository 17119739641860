<template>
  <script v-html="jsonld" type="application/ld+json" />
</template>

<script>
export default {
  name: 'StructuredDataObject',
  props: [
    'postTitle',
    'postBody',
    'postDescription',
    'postDatePublished',
    'postDateModified',
    'postImage',
    'postUrl',
  ],
  data() {
    return {
      jsonld: {
        '@context': 'https://schema.org',
        '@type': 'Article',
        author: {
          '@type': 'Organization',
          name: 'ScoreMaster',
        },
        datePublished: '',
        dateModified: '',
        headline: '',
        image: [],
        articleBody: '',
        description: '',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': '',
        },
        publisher: {
          '@type': 'Organization',
          name: 'ScoreMaster',
          logo: {
            '@type': 'ImageObject',
            url: 'https://www.scoremaster.com/ScoreMaster.png',
          },
        },
      },
    }
  },
  watch: {
    postTitle: function() {
      this.jsonld.headline = this.postTitle
      this.jsonld.headline = this.postTitle
    },
    postDatePublished: function() {
      this.jsonld.datePublished = this.postDatePublished
    },
    postDateModified: function() {
      this.jsonld.dateModified = this.postDateModified
    },
    postDescription: function() {
      this.jsonld.description = this.postDescription
    },
    postBody: function() {
      this.jsonld.articleBody = this.postBody
    },
    postUrl: function() {
      this.jsonld.mainEntityOfPage['@id'] = this.postUrl
    },
    postImage: function() {
      this.$set(this.jsonld.image, 0, this.postImage)
    },
  },
}
</script>
