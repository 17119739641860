<template>
  <div>
    <div
      v-if="activeCategory || categoryId"
      class="btn text-center btn-gradient btn-primary btn-sm rounded-pill router-link-exact-active"
    >
      {{ getCategoryName(categoryBadge) }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CategoryBadge',
  props: { categories: Array, categoryId: Number },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({ getCategoryName: 'blog/getCategoryName' }),
    categoryBadge: function() {
      if (this.$route.params.id && this.$route.name === 'BlogList') {
        return this.categories.find(x => x === parseInt(this.$route.params.id))
      } else if (this.categoryId) {
        return this.categories.find(x => x === parseInt(this.categoryId))
      } else {
        return Array.isArray(this.categories) && this.categories.length > 0
          ? this.categories[0]
          : this.categories
      }
    },
    activeCategory: function() {
      return this.categoryBadge === parseInt(this.$route.params.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-sm {
  padding: 4px 18px;
}
</style>
