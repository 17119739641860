<template>
  <div v-if="pages > 1">
    <nav aria-label="Post Pages Navigation">
      <ul class="pagination justify-content-center">
        <li class="page-item first" :class="{ disabled: currentPage == 1 }">
          <a class="page-link" @click="currentPage !== 1 ? nextPage(1) : null"
            >First</a
          >
        </li>
        <li class="page-item mr-auto" :class="{ disabled: currentPage == 1 }">
          <a
            class="page-link"
            @click="currentPage !== 1 ? nextPage(currentPage - 1) : null"
            ><IconArrow class="icon icon-gray-400 icon-arrow reverse"
          /></a>
        </li>
        <li
          class="page-item"
          :class="{ disabled: currentPage == pagesToShow[0] }"
        >
          <a
            class="page-link"
            :class="{ current: currentPage == pagesToShow[0] }"
            @click="
              pagesToShow[0] !== currentPage ? nextPage(pagesToShow[0]) : null
            "
            >{{ pagesToShow[0] }}</a
          >
        </li>
        <li
          class="page-item"
          :class="{ disabled: currentPage == pagesToShow[1] }"
        >
          <a
            class="page-link"
            :class="{ current: currentPage == pagesToShow[1] }"
            @click="
              pagesToShow[1] !== currentPage ? nextPage(pagesToShow[1]) : null
            "
            >{{ pagesToShow[1] }}</a
          >
        </li>
        <li
          v-if="pages > 2"
          class="page-item"
          :class="{ disabled: currentPage == pagesToShow[2] }"
        >
          <a
            class="page-link"
            :class="{ current: currentPage == pagesToShow[2] }"
            @click="
              pagesToShow[2] !== currentPage ? nextPage(pagesToShow[2]) : null
            "
            >{{ pagesToShow[2] }}</a
          >
        </li>
        <li
          class="page-item ml-auto"
          :class="{ disabled: currentPage == pages }"
        >
          <a
            class="page-link"
            @click="currentPage !== pages ? nextPage(currentPage + 1) : null"
            ><IconArrow class="icon icon-gray-400 icon-arrow"
          /></a>
        </li>
        <li class="page-item last" :class="{ disabled: currentPage == pages }">
          <a class="page-link" @click="nextPage(pages)">Last</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import IconArrow from '@/assets/svg/icon_arrow.svg'

export default {
  name: 'Pagination',
  components: {
    IconArrow,
  },
  props: { category: { type: Boolean, default: false } },
  data() {
    return {
      pagesToShow: [],
    }
  },
  mounted() {
    this.setPagination()
  },
  computed: mapState({
    pages(state) {
      if (this.category) {
        return state.blog.category_pages[this.$route.params.id].pages
      } else {
        return state.blog.all_posts.pages
      }
    },
    currentPage(state) {
      if (this.category) {
        return state.blog.category_pages[this.$route.params.id].current_page
      } else {
        return state.blog.all_posts.current_page
      }
    },
  }),
  watch: {
    currentPage() {
      this.setPagination()
    },
  },
  methods: {
    nextPage(page) {
      window.scrollTo({ top: 0, left: 0 })
      if (this.category) {
        this.$store.dispatch('blog/getAllPosts', {
          page: page,
          categoryId: this.$route.params.id,
        })
      } else {
        this.$store.dispatch('blog/getAllPosts', { page: page })
      }
    },
    setPagination() {
      if (this.pages > 2) {
        // On the last page
        if (this.currentPage === this.pages) {
          this.pagesToShow = [
            this.currentPage - 2,
            this.currentPage - 1,
            this.currentPage,
          ]
          // On the first page
        } else if (this.currentPage === 1) {
          this.pagesToShow = [
            this.currentPage,
            this.currentPage + 1,
            this.currentPage + 2,
          ]
        } else {
          this.pagesToShow = [
            this.currentPage - 1,
            this.currentPage,
            this.currentPage + 1,
          ]
        }
      } else {
        if (this.currentPage === this.pages) {
          this.pagesToShow = [this.currentPage - 1, this.currentPage]
          // On the first page
        } else if (this.currentPage === 1) {
          this.pagesToShow = [this.currentPage, this.currentPage + 1]
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-item {
  margin: 0 2px;
  .page-link {
    border-radius: 15px;
    color: $gray-400;
    height: 30px;
    width: 30px;
    padding: 0;
    line-height: 26px;
    text-align: center;
    font-size: 1rem;
    font-weight: $font-weight-semibold;
    cursor: pointer;

    .icon-arrow {
      width: 15px;
      height: 15px;
    }

    svg.reverse {
      transform: rotate(180deg);
    }

    &:hover {
      background: $gray-400;
      color: $white;

      svg {
        fill: $white !important;
      }
    }

    &.current {
      background: $primary;
      color: $white;
    }
  }

  &:first-child .page-link,
  &:last-child .page-link {
    width: 80px;
  }

  &.disabled .page-link:not(.current) {
    opacity: 0.5;
    background: $gray-100;
  }
}

@include media-breakpoint-up(md) {
  .page-item {
    margin: 0 5px;
    &.mr-auto {
      margin-right: 24px !important;
    }
    &.ml-auto {
      margin-left: 24px !important;
    }
    .page-link {
      border-radius: 25px;
      height: 50px;
      width: 50px;
      line-height: 48px;
      font-size: 1.3rem;
      .icon-arrow {
        width: 22px;
        height: 22px;
      }
    }
    &:first-child .page-link,
    &:last-child .page-link {
      width: 100px;
    }
  }
}
</style>
