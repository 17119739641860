<template>
  <div>
    <div class="promo-1 align-items-center justify-content-center d-flex py-3">
      <h4 class="text-primary">Want More Points?</h4>
      <SignupLinkButton :class="'w-auto'" size="sm" pill to="/get-started/"
        >Join ScoreMaster<sup>&reg;</sup></SignupLinkButton
      >
    </div>
    <div class="home-promo" v-if="$route.name === 'BlogHome'">
      <vue-apng
        src="/header_scoremaster_tiles.apng"
        alt="ScoreMaster®"
      ></vue-apng>
      <div class="blue-promo">
        <h1>Blogs for Money &amp; Credit</h1>
      </div>
    </div>
    <div class="promo-2 py-3">
      <b-container class="d-flex align-items-start justify-content-between">
        <div
          class="trigger"
          @click="overflowTags ? (openCat = !openCat) : null"
        >
          <h4 class="font-weight-bold text-primary my-0">
            Hot &amp; Trending
            <IconCaret
              v-if="overflowTags"
              class="icon icon-primary transition"
              :class="{ 'rotate-180': openCat }"
            />
          </h4>
        </div>
        <div class="categories-list" :class="{ open: openCat }">
          <transition-group
            name="list"
            tag="ul"
            class="list-inline mb-0"
            ref="taglist"
          >
            <li
              v-for="category in sortedCategories"
              :key="category.id"
              class="list-inline-item"
            >
              <b-button
                :to="{
                  name: 'BlogList',
                  params: { id: category.id, slug: category.slug },
                }"
                pill
                size="sm"
                variant="primary"
                class="text-center btn-gradient"
                :class="{ 'link-active': category.id === refCategory }"
              >
                {{ category.name }}
              </b-button>
            </li>
          </transition-group>
        </div>
        <div class="all-blogs">
          <b-button
            v-if="$route.params.id"
            :to="{ name: 'BlogHome' }"
            variant="link"
            class="text-center"
            >All Blogs</b-button
          >
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import debounce from '@/helpers/debounce'
import VueApng from 'vue-apng'
import IconCaret from '@/assets/svg/icon_caretUp.svg'
export default {
  name: 'BlogHeader',
  components: {
    IconCaret,
    VueApng,
  },
  props: ['refCategory'],
  data() {
    return {
      openCat: false,
      overflowTags: false,
    }
  },
  created() {
    if (!this.$store.getters['blog/getAllCategories'].length) {
      this.$store.dispatch('blog/getAllCategories')
    }
    window.addEventListener('resize', this.checkTagsOverflow)
  },
  destroyed() {
    window.removeEventListener('resize', this.checkTagsOverflow)
  },
  watch: {
    categories() {
      this.$nextTick(function() {
        this.overflowTags = this.$refs.taglist.$el.offsetHeight > 48
      })
    },
  },
  computed: {
    ...mapState({
      categories: state => state.blog.all_categories,
    }),
    referrerId: function() {
      return this.refCategory ? this.refCategory : this.$route.params.id
    },
    // Sort active category to the first position
    sortedCategories: function() {
      return this.referrerId
        ? [...this.categories].sort((x, y) =>
            x.id === parseInt(this.referrerId)
              ? -1
              : y.id === parseInt(this.referrerId)
              ? 1
              : 0
          )
        : this.categories
    },
  },
  methods: {
    checkTagsOverflow: debounce(function() {
      this.overflowTags = this.$refs.taglist.$el.offsetHeight > 48
    }),
  },
}
</script>

<style lang="scss">
.promo-1 {
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.19),
    inset 0px -5px 5px -8px rgba(0, 0, 0, 0.5);
  h4 {
    margin-right: 20px;
    margin-bottom: 0;
  }
}
.promo-2 {
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.05),
    inset 0px -5px 5px -8px rgba(0, 0, 0, 0.2);
  h4 {
    font-size: 1.25rem;
  }
  .trigger {
    margin-top: 13px;
    margin-right: 30px;
    margin: 13px 30px 0 auto;
    cursor: pointer;
  }
  .all-blogs {
    margin-left: auto;
    a {
      white-space: nowrap;
    }
  }
  .btn-sm {
    padding: 4px 18px;
    line-height: 20px;
  }
  .categories-list {
    overflow: hidden;
    max-height: 48px;
    height: 100%;
    transition: all 0.3s ease;
    .list-inline-item {
      margin: 10px;
    }
    &.open {
      max-height: 300px;
    }
  }
}
.home-promo {
  .apng-wrapper {
    text-align: center;
    img {
      position: relative;
      margin: 30px 0;
      max-width: 400px;
    }
  }
  .blue-promo {
    width: 100%;
    background: $primary;
    padding: 10px 0;
    text-align: center;
    position: relative;
    &:after {
      content: '';
      border-top: 0;
      border-right: 1rem solid transparent;
      border-bottom: 1rem solid $primary;
      border-left: 1rem solid transparent;
      top: -1rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    h1 {
      margin: 0;
      color: $white;
      font-weight: bold;
    }
  }
}
@include media-breakpoint-down(md) {
  .promo-1 {
    h4 {
      font-size: 1.25rem;
      margin: 0 8px 0 0;
    }
    .signup-link {
      font-size: 12px;
      padding: 3px 8px;
    }
  }
  .home-promo {
    .apng-wrapper {
      img {
        max-width: 75%;
      }
    }
  }
  .promo-2 {
    h4 {
      font-size: 1rem;
    }
    & > div {
      &.d-flex {
        flex-wrap: wrap;
      }
      .trigger {
        order: 0;
        margin-left: initial;
        min-width: fit-content;
      }
      .all-blogs {
        order: 1;
      }
      .categories-list {
        order: 2;
        max-width: 100%;
        .list-inline-item {
          margin: 10px 3px;
          text-align: center;
          a {
            display: block;
          }
        }
      }
    }
  }
}
</style>
