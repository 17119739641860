<template>
  <transition name="fade">
    <div v-if="posts">
      <ul class="list-unstyled post-list">
        <li
          v-for="(post, index) in posts"
          :key="post.id"
          class="post mb-4 pb-4"
          @click="
            $router.push({
              name: 'BlogPost',
              params: { id: post.id, slug: post.slug },
            })
          "
          :class="{ featured: showFeatured && index === 0 }"
        >
          <div class="image">
            <img
              v-if="post.media_url"
              :src="post.media_url"
              :alt="post.title.rendered"
            />
          </div>
          <div class="excerpt">
            <p class="h6">
              <router-link
                :to="{
                  name: 'BlogPost',
                  params: { id: post.id, slug: post.slug },
                }"
                v-html="$sanitize(post.title.rendered)"
              ></router-link>
            </p>
            <CategoryBadge
              :categories="post.categories"
              :categoryId="categoryId"
            />
            <p class="mb-0 text-gray-400">
              <BlogAuthor :id="post.author" />
            </p>
          </div>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
import BlogAuthor from './BlogAuthor'
import CategoryBadge from './CategoryBadge'

export default {
  name: 'PostList',
  components: {
    BlogAuthor,
    CategoryBadge,
  },
  props: { posts: Array, showFeatured: Boolean, categoryId: Number },
}
</script>

<style lang="scss" scoped>
.post-list {
  font-size: 14px;
  .post {
    .image {
      img {
        width: 100%;
      }
    }
    &.featured {
      position: relative;
      height: 240px;
      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 240px;
        overflow: hidden;
        img {
          object-fit: cover;
        }
      }
      .excerpt {
        z-index: 2;
        position: relative;
        height: 240px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding: 20px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
        p.h6 {
          font-size: 22px;
          color: $white;
          a:hover {
            color: $white;
          }
        }
        .text-gray-400 {
          color: $white !important;
          margin-top: 15px;
        }
      }
    }
    &:not(.featured) {
      display: flex;
      border-bottom: 1px solid $gray-200;
      .image {
        margin-right: 10px;
        width: 90px;
        min-width: 90px;
        img {
          width: 90px;
        }
      }
      .excerpt {
        margin-right: 20px;
        .text-gray-400 {
          margin-top: 5px;
        }
      }
      .dots {
        margin-left: auto;
        margin-right: 20px;
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .post-list {
    .post {
      &.featured {
        height: 400px;
        .image {
          height: 400px;
        }
        .excerpt {
          height: 400px;
          padding: 40px;
          p.h6 {
            font-size: 36px;
            padding-right: 40px;
            margin-bottom: 30px;
          }
          .text-gray-400 {
            font-size: 16px;
          }
        }
      }
      &:not(.featured) {
        .excerpt {
          margin-right: 0;
          p.h6 {
            margin-bottom: 30px;
          }
        }
        .image {
          margin-right: 30px;
          width: 140px;
          min-width: 140px;
          img {
            width: 140px;
          }
        }
      }
    }
  }
}
</style>
