<template>
  <span>by {{ name }}</span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BlogAuthor',
  props: {
    id: Number,
  },
  data() {
    return {
      name: '',
    }
  },
  created() {
    if (!this.$store.getters['blog/isAuthorLoaded']) {
      if (!this.$store.getters['blog/isAuthorLoading']) {
        this.$store.dispatch('blog/getAllAuthors')
      }
    } else {
      this.lookupAuthor(this.id)
    }
  },
  methods: {
    lookupAuthor(id) {
      this.name = this.$store.getters['blog/getAuthorName'](id)
    },
  },
  computed: mapState({
    authors: state => state.blog.authors,
  }),
  watch: {
    authors() {
      this.lookupAuthor(this.id)
    },
  },
}
</script>
