<template>
  <div>
    <keep-alive><BlogHeader :refCategory="refCategoryId"/></keep-alive>
    <b-container v-if="!notfound">
      <b-row align-h="center" class="mb-5" v-if="showPost">
        <b-col cols="12" lg="10" xl="8">
          <div class="hero">
            <div class="hero-content">
              <h1 class="h2" v-html="$sanitize(post.title.rendered)"></h1>
              <b-button
                v-if="refCategory"
                pill
                size="sm"
                variant="primary"
                class="text-center btn-gradient link-active"
              >
                {{ refCategory }}
              </b-button>
              <p><BlogAuthor :id="post.author" /></p>
            </div>
            <div class="image">
              <img
                v-if="post.media_url"
                :src="post.media_url"
                :alt="post.title.rendered"
                class="w-100"
              />
            </div>
          </div>
          <ShareButtons :pageTitle="postTitle" />
        </b-col>
        <div class="w-100"></div>
        <b-col cols="12" lg="10" xl="8">
          <div
            v-html="
              $sanitize(post.content.rendered, {
                allowedTags: $sanitize.defaults.allowedTags.concat([
                  'img',
                  'h2',
                  'iframe',
                ]),
              })
            "
            class="post"
          ></div>
          <p><BlogAuthor :id="post.author" /> {{ post.date | date }}</p>
        </b-col>
        <b-col cols="12" lg="10" xl="8">
          <RelatedPosts :catId="refCategoryId || post.categories" />
        </b-col>
      </b-row>
      <Loader v-else class="mt-5" />
      <StructuredDataObject
        :postTitle="postTitle"
        :postBody="postBody"
        :postDescription="postDescription"
        :postDatePublished="postDatePublished"
        :postDateModified="postDateModified"
        :postImage="postImage"
        :postUrl="postUrl"
      />
    </b-container>
    <b-container v-else>
      <b-row align-h="center" class="mb-5">
        <b-col cols="12" lg="10" xl="8">
          <div class="py-5">
            <h1 class="h1 font-weight-bolder mb-3">Oh no!</h1>
            <p class="mb-5">
              Sorry, we weren't able to find this blog post.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ShareButtons from '@/components/blog/ShareButtons'
import RelatedPosts from '@/components/blog/RelatedPosts'
import BlogHeader from '@/components/blog/BlogHeader'
import StructuredDataObject from '@/components/blog/StructuredDataObject'
import BlogAuthor from '@/components/blog/BlogAuthor'

export default {
  name: 'BlogPost',
  components: {
    ShareButtons,
    RelatedPosts,
    BlogHeader,
    BlogAuthor,
    StructuredDataObject,
  },
  metaInfo() {
    return {
      title: this.postTitle,
      titleTemplate: c => `${c} | ScoreMaster®`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.postDescription,
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'article',
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: this.postTitle,
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.postDescription,
        },

        {
          vmid: 'og:image',
          property: 'og:image',
          content: this.postImage,
        },
        {
          vmid: 'twitter:title',
          property: 'twitter:title',
          content: this.postTitle,
        },
        {
          vmid: 'twitter:description',
          property: 'twitter:description',
          content: this.postDescription,
        },
        {
          vmid: 'twitter:image',
          property: 'twitter:image',
          content: this.postImage,
        },
        {
          vmid: 'twitter:image:src',
          property: 'twitter:image:src',
          content: this.postImage,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: this.postUrl,
        },
      ],
    }
  },
  data() {
    return {
      showPost: false,
      notfound: false,
      postTitle: undefined,
      postUrl: undefined,
      postBody: undefined,
      postDescription: undefined,
      postImage: undefined,
      postDatePublished: undefined,
      postDateModified: undefined,
      refCategory: undefined,
      refCategoryId: undefined,
    }
  },
  created() {
    this.$store.dispatch('blog/getPost', this.$route.params.id)
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name) vm.$store.dispatch('blog/getPost', to.params.id)
      if (from.params.id) {
        vm.refCategoryId = parseInt(from.params.id)
        vm.refCategory = vm.$store.getters['blog/getCategoryName'](
          vm.refCategoryId
        )
      }
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.showPost = false
    this.$store.dispatch('blog/getPost', to.params.id)
    next()
  },
  computed: mapState({
    post: state => state.blog.post,
    status: state => state.blog.status,
  }),
  watch: {
    post() {
      this.showPost = true
      this.postTitle = this.post.title.rendered
      this.postUrl = this.post.link
      this.postBody = this.post.content.rendered
      this.postDescription = this.post.excerpt.rendered
      this.postImage = this.post.media_url
      this.postDatePublished = this.post.date
      this.postDateModified = this.post.modified

      document.dispatchEvent(new Event('x-app-rendered'))
    },
    status() {
      if (this.status === 'notfound') this.notfound = true
    },
  },
}
</script>

<style lang="scss" scoped>
.loader {
  left: 50%;
  transform: translateX(-50%);
  top: 50px;
}
.btn-gradient {
  font-size: 12px;
  width: fit-content;
  margin: 5px 0;
  padding: 4px 16px;
}
</style>
