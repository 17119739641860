<template>
  <div class="share-icons">
    <div class="twitter-share">
      <a
        href="https://twitter.com/share"
        class="twitter-share-button"
        data-show-count="false"
        >Tweet</a
      >
    </div>
    <div class="fb-share">
      <div id="fb-root"></div>
      <div
        class="fb-share-button"
        v-bind:data-href="shareUrl"
        data-layout="button"
        data-size="small"
      ></div>
    </div>
    <div class="tumblr-share">
      <a class="tumblr-share-button" href="https://www.tumblr.com/share"></a>
    </div>
    <div ref="linkedin" class="linkedin-share"></div>
    <div class="reddit-share">
      <a
        v-bind:href="
          `https://www.reddit.com/submit?url=${shareUrl}&title=${encodeURI(
            pageTitle
          )}`
        "
        ><IconReddit class="reddit-icon" /> Share</a
      >
    </div>
    <div class="email-share">
      <a
        v-bind:href="
          `mailto:?subject=${pageTitle}&amp;body=I thought you might like to read this article: ${shareUrl}`
        "
        title="Share by Email"
        ><IconEmail class="email-icon" /> Email
      </a>
    </div>
  </div>
</template>

<script>
import IconReddit from '@/assets/svg/icon_reddit.svg'
import IconEmail from '@/assets/svg/icon_email.svg'

export default {
  name: 'ShareButtons',
  metaInfo() {
    return {
      script: [
        {
          src: 'https://platform.twitter.com/widgets.js',
          async: true,
          defer: true,
        },
        {
          src: 'https://connect.facebook.net/en_US/sdk.js',
          async: true,
          defer: true,
        },
      ],
    }
  },
  props: { pageTitle: String },
  components: {
    IconReddit,
    IconEmail,
  },
  data() {
    return {
      shareUrl: `${location.protocol}//${location.host}${this.$route.fullPath}`,
    }
  },
  mounted() {
    let facebookScript = document.createElement('script')
    facebookScript.src =
      'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0'
    facebookScript.async = true
    facebookScript.defer = true
    facebookScript.crossorigin = 'anonymous'
    facebookScript.nonce = 'xDJn2zdO'
    document.head.appendChild(facebookScript)

    let tumblrScript = document.createElement('script')
    tumblrScript.src = 'https://assets.tumblr.com/share-button.js'
    tumblrScript.async = true
    document.head.appendChild(tumblrScript)

    let linkedinScript = document.createElement('script')
    linkedinScript.src = 'https://platform.linkedin.com/in.js'

    let linkedinScript2 = document.createElement('script')
    linkedinScript2.src = 'https://platform.linkedin.com/in.js'
    linkedinScript2.type = 'IN/Share'
    linkedinScript2['data-url'] = this.shareUrl

    this.$refs.linkedin.appendChild(linkedinScript)
    this.$refs.linkedin.appendChild(linkedinScript2)
  },
}
</script>

<style lang="scss" scoped>
a:hover {
  color: inherit;
}
</style>
