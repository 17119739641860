<template>
  <div :key="$route.params.id">
    <keep-alive><BlogHeader /></keep-alive>
    <b-container>
      <b-row align-h="center" class="my-5">
        <b-col cols="12" md="8" xl="7">
          <div v-if="posts">
            <PostList :posts="posts" :showFeatured="currentPage === 1" />
            <Pagination :category="true" />
          </div>
          <Loader v-else class="mt-5" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BlogHeader from '@/components/blog/BlogHeader'
import PostList from '@/components/blog/PostList'
import Pagination from '@/components/blog/Pagination'
export default {
  name: 'BlogList',
  components: {
    BlogHeader,
    PostList,
    Pagination,
  },
  metaInfo() {
    return {
      title: this.pageTitle,
      titleTemplate: c => `Resources: ${c} | ScoreMaster®`,
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: `Resources: ${this.pageTitle} | ScoreMaster®`,
        },
        {
          vmid: 'twitter:title',
          property: 'twitter:title',
          content: `Resources: ${this.pageTitle} | ScoreMaster®`,
        },
      ],
      link: [
        {
          vmid: 'canonical',
          rel: 'canonical',
          href: `https://www.scoremaster.com/blog/${this.$route.params.id}/${this.$route.params.slug}`,
        },
      ],
    }
  },
  data() {
    return {
      pageTitle: undefined,
    }
  },
  created() {
    if (!this.$store.getters['blog/getCategoryPosts'](this.$route.params.id)) {
      this.$store.dispatch('blog/getAllPosts', {
        categoryId: this.$route.params.id,
      })
    }
  },
  computed: mapState({
    posts: function() {
      return this.$store.getters['blog/getCategoryPosts'](this.$route.params.id)
    },
    currentPage(state) {
      return state.blog.category_pages[this.$route.params.id].current_page
    },
  }),
  watch: {
    posts: function() {
      this.pageTitle = this.$store.getters['blog/getCategoryName'](
        parseInt(this.$route.params.id)
      )
      document.dispatchEvent(new Event('x-app-rendered'))
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name) {
        if (!vm.$store.getters['blog/getCategoryPosts'](to.params.id)) {
          vm.$store.dispatch('blog/getAllPosts', {
            categoryId: to.params.id,
          })
        }
      }
    })
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.$store.getters['blog/getCategoryPosts'](to.params.id))
      this.$store.dispatch('blog/getAllPosts', {
        categoryId: to.params.id,
      })
    next()
  },
}
</script>

<style lang="scss" scoped>
.post {
  .image {
    background: #999;
    width: 100%;
    height: 230px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}
.loader {
  left: 50%;
  transform: translateX(-50%);
}
</style>
