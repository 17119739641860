<template>
  <div>
    <keep-alive><BlogHeader /></keep-alive>
    <b-container>
      <b-row align-h="center" class="my-5">
        <b-col cols="12" md="8" xl="7">
          <div v-if="posts.length">
            <PostList :posts="posts" :showFeatured="currentPage === 1" />
            <Pagination />
          </div>
          <Loader v-else class="mt-5" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BlogHeader from '@/components/blog/BlogHeader'
import PostList from '@/components/blog/PostList'
import Pagination from '@/components/blog/Pagination'

export default {
  name: 'BlogHome',
  components: {
    BlogHeader,
    PostList,
    Pagination,
  },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://www.scoremaster.com/blog/',
        },
      ],
    }
  },
  created() {
    if (!this.$store.getters['blog/getAllPosts'].length) {
      this.$store.dispatch('blog/getAllPosts', { page: this.currPage })
    }
  },
  computed: {
    ...mapGetters({
      posts: 'blog/getAllPosts',
      currPage: 'blog/getCurrentPage',
    }),
    ...mapState({
      currentPage: state => state.blog.all_posts.current_page,
    }),
  },
  watch: {
    posts: () => {
      setTimeout(function() {
        document.dispatchEvent(new Event('x-app-rendered'))
      }, 1000)
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.$store.getters['blog/getCategoryPosts'](to.params.id))
      this.$store.dispatch('blog/getAllPosts', {
        categoryId: to.params.id,
      })
    next()
  },
}
</script>

<style lang="scss" scoped>
.post {
  .image {
    background: #999;
    width: 100%;
    height: 230px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}
.loader {
  left: 50%;
  transform: translateX(-50%);
}
</style>
