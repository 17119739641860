<template>
  <div v-if="$store.state.blog.status === 'complete'" class="related-posts">
    <h6 class="mt-5 mb-3"><strong>You Might Also Like</strong></h6>
    <PostList
      :posts="posts"
      :categoryId="!Array.isArray(this.catId) ? catId : null"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PostList from '@/components/blog/PostList'
export default {
  name: 'RelatedPosts',
  components: { PostList },
  data() {
    return {}
  },
  created() {
    if (!this.$store.getters['blog/getCategoryPosts'](this.categoryId)) {
      this.$store.dispatch('blog/getAllPosts', {
        categoryId: this.categoryId,
      })
    }
  },
  props: ['catId'],
  computed: {
    ...mapState({
      categories: state => state.blog.all_categories,
    }),
    categoryId: function() {
      return Array.isArray(this.catId) && this.catId.length
        ? this.catId[0]
        : this.catId
    },
    posts: function() {
      return this.$store.getters['blog/getCategoryPosts'](this.categoryId)
        ? this.$store.getters['blog/getCategoryPosts'](this.categoryId)
            .filter(x => x.id !== parseInt(this.$route.params.id))
            .slice(0, 3)
        : []
    },
  },
}
</script>

<style lang="scss" scoped>
.list-group {
  padding: 0.5rem 1rem 1rem;
}
.list-group-item {
  border-width: 0 0 1px;
  background: none;
  padding: 1.2rem 0.5rem;
  &:first-child,
  &:last-child {
    border-radius: 0;
  }
  &:last-child {
    border-bottom: none;
  }
  &.router-link-exact-active {
    color: $primary;
  }
}
</style>
