var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('keep-alive',[_c('BlogHeader',{attrs:{"refCategory":_vm.refCategoryId}})],1),(!_vm.notfound)?_c('b-container',[(_vm.showPost)?_c('b-row',{staticClass:"mb-5",attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"cols":"12","lg":"10","xl":"8"}},[_c('div',{staticClass:"hero"},[_c('div',{staticClass:"hero-content"},[_c('h1',{staticClass:"h2",domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.post.title.rendered))}}),(_vm.refCategory)?_c('b-button',{staticClass:"text-center btn-gradient link-active",attrs:{"pill":"","size":"sm","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.refCategory)+" ")]):_vm._e(),_c('p',[_c('BlogAuthor',{attrs:{"id":_vm.post.author}})],1)],1),_c('div',{staticClass:"image"},[(_vm.post.media_url)?_c('img',{staticClass:"w-100",attrs:{"src":_vm.post.media_url,"alt":_vm.post.title.rendered}}):_vm._e()])]),_c('ShareButtons',{attrs:{"pageTitle":_vm.postTitle}})],1),_c('div',{staticClass:"w-100"}),_c('b-col',{attrs:{"cols":"12","lg":"10","xl":"8"}},[_c('div',{staticClass:"post",domProps:{"innerHTML":_vm._s(
            _vm.$sanitize(_vm.post.content.rendered, {
              allowedTags: _vm.$sanitize.defaults.allowedTags.concat([
                'img',
                'h2',
                'iframe' ]),
            })
          )}}),_c('p',[_c('BlogAuthor',{attrs:{"id":_vm.post.author}}),_vm._v(" "+_vm._s(_vm._f("date")(_vm.post.date)))],1)]),_c('b-col',{attrs:{"cols":"12","lg":"10","xl":"8"}},[_c('RelatedPosts',{attrs:{"catId":_vm.refCategoryId || _vm.post.categories}})],1)],1):_c('Loader',{staticClass:"mt-5"}),_c('StructuredDataObject',{attrs:{"postTitle":_vm.postTitle,"postBody":_vm.postBody,"postDescription":_vm.postDescription,"postDatePublished":_vm.postDatePublished,"postDateModified":_vm.postDateModified,"postImage":_vm.postImage,"postUrl":_vm.postUrl}})],1):_c('b-container',[_c('b-row',{staticClass:"mb-5",attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"cols":"12","lg":"10","xl":"8"}},[_c('div',{staticClass:"py-5"},[_c('h1',{staticClass:"h1 font-weight-bolder mb-3"},[_vm._v("Oh no!")]),_c('p',{staticClass:"mb-5"},[_vm._v(" Sorry, we weren't able to find this blog post. ")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }